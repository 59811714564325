import React from 'react';
import { Box, Skeleton } from '@mui/material';

const PostSkeleton = () => {
    return (
        <Box sx={{ width: 200, height: 300, position: 'relative', overflow: 'hidden' }}>
            <Skeleton variant="rectangular" width="100%" height="100%" />
            <Box 
                sx={{ 
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="text" width="60%" />
            </Box>
        </Box>
    );
};

export default PostSkeleton;
