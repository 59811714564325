import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import QuoteCard from './components/quotecard';
import { API_BASE_URL, API_ENDPOINTS } from '../../mainComponents/api.js';
import { motion } from 'framer-motion';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { ThemeContext } from '../../mainComponents/theme.js';
import {  useNavigate } from "react-router-dom";
import FullScreenLoading from '../../components/loadingScreen.js';
import BackButton from '../../components/backbutton.js';

const useQuotes = (page, limit = 1) => {
    const [quotes, setQuotes] = useState([]);
    const [loading, setLoading] = useState(true);
    

    useEffect(() => {
        const fetchQuotes = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.QUOTE}?page=${page}&limit=${limit}`);
                const data = await response.json();
                setQuotes(data);
            } catch (error) {
                console.error('Error fetching quotes:', error);
            }
            setLoading(false);
        };

        fetchQuotes();
    }, [page, limit]);

    return { quotes, loading };
};

const QuotesPage = () => {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 5;
    const { quotes, loading } = useQuotes(currentPage, limit);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const { isDarkMode } = React.useContext(ThemeContext);

    const paginationStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '3rem',
        gap: '1rem',
    };

    const pageButtonStyle = {
        backgroundColor: isDarkMode ? '#047D8D' : '#f8f9fa',
        color: isDarkMode ? '#ecf0f1' : '#333',
        border: 'none',
        padding: '0.5rem 1rem',
        borderRadius: '50px',
        fontWeight: 'bold',
        transition: 'all 0.3s ease',
    };

    const arrowButtonStyle = {
        ...pageButtonStyle,
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
    };
    const handleBack = () => {
        navigate(`/`);
    }
    const backButtonStyle = {
        marginRight: '10px', // Space between button and input
    };
    

    return (
        <Container className="my-5" >
            <BackButton handleBack={handleBack} style={backButtonStyle}/>
            <h1 className="text-center mb-5 fw-bold text-primary">Inspiring Book Quotes</h1>
            
            {loading ? (
                <FullScreenLoading/>
            ) : (
                <>
                    <Row className="g-4">
                        {quotes.map((quote, index) => (
                            <Col key={index} md={6} lg={4} className="mb-4">
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: index * 0.1 }}
                                >
                                    <QuoteCard
                                        quote={quote.quote}
                                        bookName={quote.book}
                                        explaination={quote.explaination}
                                    />
                                </motion.div>
                            </Col>
                        ))}
                    </Row>
                    <div style={paginationStyle}>
                        <Button
                            style={arrowButtonStyle}
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            <FaArrowLeft /> Previous
                        </Button>
                        <div style={{
                            ...pageButtonStyle,
                            backgroundColor: '#047D8D',
                            color: '#fff',
                        }}>
                            {currentPage}
                        </div>
                        <Button
                            style={arrowButtonStyle}
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={quotes.length < limit}
                        >
                            Next <FaArrowRight />
                        </Button>
                    </div>
                </>
            )}
        </Container>
    );
};

export default QuotesPage;
