import React, { useState } from 'react';
import axios from 'axios';
import { API_BASE_URL, API_ENDPOINTS } from '../../../mainComponents/api';
const FileDeleteComponent = () => {
  const [filename, setFilename] = useState('');
  const [message, setMessage] = useState('');

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`${API_BASE_URL}${API_ENDPOINTS.DELETEFILE}/${filename}`);
      setMessage(response.data.message);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setMessage(`There isn't a file named ${filename}`);
      } else {
        setMessage('An error occurred while deleting the file');
      }
    }
  };

  return (
    <div>
      <input
        type="text"
        value={filename}
        onChange={(e) => setFilename(e.target.value)}
        placeholder="Enter filename to delete"
      />
      <button onClick={handleDelete}>Delete File</button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default FileDeleteComponent;