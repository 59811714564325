import React, { useState, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ThemeContext } from '../../mainComponents/theme.js';
import "../../App.css"
import LRheader from "./components/HeaderLR";
import { API_BASE_URL, API_ENDPOINTS } from '../../mainComponents/api.js';
import {
    Box,
    Typography,
    TextField,
    Button,
    Container,
    Link,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

export default function LoginPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [redirect, setRedirect] = useState(false);
    const { isDarkMode } = useContext(ThemeContext);
    const navigate = useNavigate();
    
    async function login(ev) {
        ev.preventDefault();
        const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.LOGIN}`, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: { 'Content-Type': 'application/json' }
        });

        if (response.status === 200) {
            const data = await response.json();
            localStorage.setItem('username', data.username);
            setRedirect(true);
            window.location.reload();
        } else {
            alert('Invalid username or password');
        }
    }

    if (localStorage.getItem('username') || redirect) {
        return <Navigate to="/library" />;
    }

    return (
        <Box sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: isDarkMode ? '#011111fd' : '#ffff',
        }}>
            <LRheader />
            
            <Container component="main" maxWidth="xs" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                <Box
                    sx={{
                        width: '100%',
                        padding: 4,
                        borderRadius: '20px',
                        backdropFilter: 'blur(10px)',
                        backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(255, 255, 255, 0.8)',
                        boxShadow: isDarkMode ? '0 4px 30px rgba(0, 0, 0, 0.1)' : '0 4px 30px rgba(0, 0, 0, 0.1)',
                        border: isDarkMode ? '1px solid rgba(255, 255, 255, 0.1)' : '1px solid rgba(255, 255, 255, 0.3)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <LockOutlinedIcon sx={{ fontSize: 40, mb: 2, color: isDarkMode ? 'rgb(4, 125, 141)' : 'rgb(4, 125, 141)' }} />
                    <Typography component="h1" variant="h5" sx={{ mb: 3, color: isDarkMode ? '#fff' : 'rgb(4, 125, 141)' }}>
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={login} sx={{ mt: 1, width: '100%' }}>
                    <TextField
    margin="normal"
    required
    fullWidth
    id="username"
    label="Username"
    name="username"
    autoComplete="username"
    autoFocus
    value={username}
    onChange={(ev) => setUsername(ev.target.value)}
    sx={{
        '& .MuiInputBase-input': {
            color: isDarkMode ? '#fff' : 'inherit',
            backgroundColor: 'transparent', // Ensure background is transparent
        },
        '& .MuiInputLabel-root': {
            color: isDarkMode ? '#bbb' : 'inherit',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.23)',
            },
            '&:hover fieldset': {
                borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
            },
            '&.Mui-focused fieldset': {
                borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.9)' : 'rgb(4, 125, 141)',
            },
            // Prevent grey background on focus
            '&.Mui-focused .MuiInputBase-input': {
                backgroundColor: 'transparent', // Keep it transparent on focus
            },
        },
    }}
/>
    <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        value={password}
        onChange={(ev) => setPassword(ev.target.value)}
        sx={{
            '& .MuiInputBase-input': {
                color: isDarkMode ? '#fff' : 'inherit',
                backgroundColor: 'transparent',
            },
            '& .MuiInputLabel-root': {
                color: isDarkMode ? '#bbb' : 'inherit',
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.3)' : 'rgba(0, 0, 0, 0.23)',
                },
                '&:hover fieldset': {
                    borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
                },
                '&.Mui-focused fieldset': {
                    borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgb(4, 125, 141)',
                },
            },
        }}
    />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                backgroundColor: 'rgb(4, 125, 141)',
                                '&:hover': {
                                    backgroundColor: '#115293',
                                },
                            }}
                        >
                            Sign In
                        </Button>
                        <Box sx={{ textAlign: 'center' }}>
                            <Link
                                component="button"
                                variant="body2"
                                onClick={() => navigate('/register')}
                                sx={{ color: 'rgb(4, 125, 141)' }}
                            >
                                Don't have an account? Sign Up
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}