export const API_BASE_URL = "https://readquest.onrender.com"
// http://127.0.0.1:8000
//https://readquest.onrender.com
// You can also define other API-related constants here
export const API_ENDPOINTS = {
  LOGIN: '/login',
  REGISTER: '/register',
  ENTRY: '/entry',
  QUEST:'/quest',
  FETCH: '/fetch',
  POST: '/post',
  QUESTIONS: '/questions',
  FETCH_QUESTIONS: '/question',
  DELETEFILE:'/delete-file',
  LISTFILES:"/list-files",
  BOOK:"/book",
  QUOTE:"/quotes",
  UQUOTE: "/uquotes",
  SEARCH:"/search",
  // Add more endpoints as needed
};