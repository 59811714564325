import React, { useContext } from 'react';
import { Typography, Button, Box, Container, Grid, Card, CardContent, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ThemeContext } from '../../mainComponents/theme';
// import bookImage from "../images/book.jpg";
import book1 from '../../images/alchemist.jpg';
import book2 from '../../images/anxiousppl.jpg';
import book3 from '../../images/the-power-of-discipline-cover.png';
import book4 from '../../images/think fast and slow.jpg';
import book5 from '../../images/psymoney.jpg';
import book6 from '../../images/wolfhall.jpg';
import book7 from '../../images/malice.jpg';
import book8 from '../../images/secondhandTime.jpg';
import book9 from '../../images/tuesdays with morrie.jpg';
import book10 from '../../images/NeverLetmeGo.jpg';
import book11 from '../../images/gilead.jpg';
import MovingBookCovers from './components/carousal';

const bookCovers = [book1, book2, book3, book4, book5,book6, book7, book8, book9, book10, book11];

const HomePage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: isDarkMode ? 'rgba(10, 177, 77, 0)' : 'rgba(0, 30, 30, 0.05)' }}>

      {/* Hero Section */}
      <Box
        sx={{
          // backgroundImage: `url(${bookImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '50vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',

          position: 'relative',
          borderRadius: '10px',

        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            // backgroundColor: 'rgba(0,0,0,0.6)',
          }}
        />
        <Container maxWidth="md" sx={{ position: 'relative', zIndex: 2, py: 4, color: isDarkMode ? 'white' : 'black', }}>
          <Typography
            variant={isMobile ? "h3" : "h1"}
            component="h2"
            gutterBottom
            sx={{
              fontWeight: 'bold',
              textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
              fontFamily: '"Oswald", sans-serif',
              mb: 0,
              
            }}
          >
            Quick Wisdom on the Go

          </Typography>
          
          <MovingBookCovers bookCovers={bookCovers} />
          {/* <Typography
            variant={isMobile ? "h6" : "h4"}
            paragraph
            sx={{
              mb: 4,
              textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
            }}
          >
            Discover our vast library of book summaries and inspiring quotes, perfect for learning on the move.
          </Typography> */}
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 1 }}>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate('/library')}
              sx={{
                backgroundColor: 'rgba(4, 125, 141, 0.8)',
                '&:hover': { backgroundColor: 'rgb(4, 125, 141)' },
                fontSize: isMobile ? '1rem' : '1.2rem',
                padding: isMobile ? '8px 16px' : '12px 24px',
                borderRadius: '50px', 
                fontFamily: '"Oswald", sans-serif',
                boxShadow: '0 4px 6px rgba(236, 6, 6, 0.1)',
              }}
            >
              Explore BOOK Summaries
            </Button>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate('/quotes')}
              sx={{
                backgroundColor: 'rgba(233, 30, 99, 0.8)',
                '&:hover': { backgroundColor: 'rgb(233, 30, 99)' },
                fontSize: isMobile ? '1rem' : '1.2rem',
                padding: isMobile ? '8px 16px' : '12px 24px',
                borderRadius: '50px',
                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                fontFamily: '"Oswald", sans-serif',
              }}
            >
              Discover Quotes
            </Button>
          </Box>
        </Container>
      </Box>




      {/* Features Section */}
      <Container maxWidth="lg" sx={{ my: 8 }}>
        <Typography
          variant="h3"
          component="h2"
          gutterBottom
          align="center"
          sx={{
            mb: 6,
            fontWeight: 'bold',
            color: isDarkMode ? 'white' : 'inherit'
          }}
        >
          Why Choose Our Summaries?
        </Typography>
        <Grid container spacing={4}>
          {[
            { title: 'Concise Content', description: 'Get key insights quickly without losing depth.', icon: '📚' },
            { title: 'Wide Selection', description: 'Access summaries from various genres and topics.', icon: '🌐' },
            { title: 'On-Demand Learning', description: 'Read anytime, anywhere on your device.', icon: '📱' },
            { title: 'Expert Curation', description: 'Summaries crafted by passionate readers and experts.', icon: '🧠' },
          ].map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <Card sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  boxShadow: 3,
                  backgroundColor: isDarkMode ? 'rgba(255,255,255,0.05)' : 'white',
                  color: isDarkMode ? 'white' : 'inherit'
                }}>
                  <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                    <Typography variant="h1" sx={{ mb: 2 }}>{feature.icon}</Typography>
                    <Typography gutterBottom variant="h5" component="h3" sx={{ fontWeight: 'bold' }}>
                      {feature.title}
                    </Typography>
                    <Typography>
                      {feature.description}
                    </Typography>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Call to Action Section */}
      <Box sx={{ bgcolor: 'rgb(4, 125, 141)', color: 'white', py: 8 }}>
        <Container maxWidth="md" sx={{ textAlign: 'center' }}>
          <Typography variant="h3" component="h2" gutterBottom sx={{ fontWeight: 'bold' }}>
            Ready to Start Learning?
          </Typography>
          <Typography variant="h5" paragraph sx={{ mb: 4 }}>
            Join thousands of readers expanding their knowledge every day.
          </Typography>
          <Button
            variant="outlined"
            size="large"
            onClick={() => navigate('/library')}
            sx={{
              color: 'white',
              borderColor: 'white',
              '&:hover': { borderColor: 'white', bgcolor: 'rgba(255,255,255,0.1)' },
              fontSize: isMobile ? '1rem' : '1.2rem',
              padding: isMobile ? '8px 16px' : '12px 24px',
            }}
          >
            Get Started Now
          </Button>
        </Container>
      </Box>
    </Box>
  );
};

export default HomePage;