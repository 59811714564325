import React from 'react';
import { IconButton } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { ThemeContext } from '../mainComponents/theme.js';

const BackButton = ({ handleBack }) => {
    const { isDarkMode } = React.useContext(ThemeContext);
    return (
        <IconButton
            onClick={handleBack}
            sx={{
                width: '36px', // Define fixed width
                height: '36px', // Define fixed height
                color: isDarkMode ? 'white' : 'black', // Adjust as per your theme
                boxShadow: isDarkMode ? '0px 2px 4px rgba(200,200, 200, 0.2)' : '0px 2px 4px rgba(0,0, 0, 0.2)', // Subtle shadow for visibility
                '&:hover': {
                    backgroundColor: 'rgba(200, 200, 200, 0.8)', // Lighter hover effect
                },
                borderRadius: '8px',
            }}
        >
            <ArrowBack />
        </IconButton>
    );
};

export default BackButton;
