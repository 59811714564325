import React, { useState } from 'react';
import axios from 'axios';
import { Button, Box } from '@mui/material';

import { API_BASE_URL, API_ENDPOINTS } from '../../../mainComponents/api';

const QuestionSubmission = () => {
    const [question, setQuestion] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const username = localStorage.getItem('username');

        if (!username) {
            alert('Please log in to submit a question.');
            return;
        }

        try {
            const response = await axios.post(`${API_BASE_URL}${API_ENDPOINTS.QUESTIONS}`, {
                question,
                username
            });

            if (response.status === 200) {
                alert('Question asked successfully!');
                setQuestion(''); // Clear the input field
            } else {
                throw new Error(response.data.detail || 'Failed to submit question');
            }
        } catch (error) {
            console.error('Error submitting question:', error);
            alert(error.response?.data?.detail || 'Failed to submit question. Please try again.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 2 }}>
                <textarea
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    placeholder="Enter your question here"
                    rows="3"  // Adjust the number of rows
                    style={{
                        width: '100%',  // Make the textarea full width
                        padding: '10px',
                        boxSizing: 'border-box',
                        resize: 'vertical',  // Allow vertical resizing
                        minHeight: '30px',  // Set a minimum height
                        maxHeight: '77px', // Set a maximum height
                    }}
                />
            </Box>
            <Button 
                type="submit" 
                variant="contained"
                fullWidth
                sx={{
                    backgroundColor: 'rgb(4, 125, 141)',
                    '&:hover': {
                        backgroundColor: '#115293',
                    },
                    color: '#fff',
                    fontWeight: 'bold',
                }}
            >
                Submit Question
            </Button>
        </form>
    );
};

export default QuestionSubmission;