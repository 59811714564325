import React, { useState, useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { Box, useMediaQuery } from "@mui/material";

// Adjustable parameters
const ANIMATION_DURATION = 40; // seconds
const MAGNIFICATION_SCALE = 1.3;
const CENTER_OFFSET_PERCENTAGE = 50; // Adjust this to move the center point
const UPDATE_INTERVAL = 100; // milliseconds

const MovingBookCovers = ({ bookCovers }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [centerIndex, setCenterIndex] = useState(0);
  const containerRef = useRef(null);
  const controls = useAnimation();
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const animateCovers = async () => {
      if (isMounted.current) {
        await controls.start({
          x: "-50%",
          transition: { duration: ANIMATION_DURATION, ease: "linear" }
        });
        if (isMounted.current) {
          controls.set({ x: "0%" });
          animateCovers();
        }
      }
    };

    if (isMounted.current) {
      animateCovers();
    }

    return () => {
      controls.stop();
    };
  }, [controls]);
  

  useEffect(() => {
    const updateCenterIndex = () => {
      if (containerRef.current) {
        const container = containerRef.current;
        const containerWidth = container.offsetWidth;
        const containerCenter = containerWidth * (CENTER_OFFSET_PERCENTAGE / 100);
        const covers = container.getElementsByClassName("book-cover");
        let closestIndex = 0;
        let minDistance = Infinity;

        Array.from(covers).forEach((cover, index) => {
          const rect = cover.getBoundingClientRect();
          const coverCenter = rect.left + rect.width / 2 - container.getBoundingClientRect().left;
          const distance = Math.abs(coverCenter - containerCenter);
          if (distance < minDistance) {
            minDistance = distance;
            closestIndex = index % bookCovers.length;
          }
        });

        setCenterIndex(closestIndex);
      }
    };

    const interval = setInterval(updateCenterIndex, UPDATE_INTERVAL);
    return () => clearInterval(interval);
  }, [bookCovers.length]);

  const calculateScale = (index) => (index === centerIndex ? MAGNIFICATION_SCALE : 1);

  return (
    <Box
      ref={containerRef}
      sx={{
        py: 4,
        overflow: "hidden",
        backgroundColor: "transparent",
        position: "relative",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", width: "200%" }}>
        <motion.div
          animate={controls}
          style={{ display: "flex", alignItems: "center" }}
        >
          {bookCovers.concat(bookCovers).map((cover, index) => (
            <motion.div
              key={index}
              className="book-cover"
              style={{
                marginRight: isMobile ? "12px" : "20px",
                boxShadow: "3px 3px 8px rgba(0,0,0,0.2)",
                transformOrigin: "center center",
                height: isMobile ? 190 : 250,
                width: isMobile ? 120 : 160,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                overflow: "hidden",
              }}
              animate={{ 
                scale: calculateScale(index % bookCovers.length),
                rotateY: index % bookCovers.length === centerIndex ? [0, 5, -5, 0] : 0
              }}
              transition={{
                scale: { duration: 0.7, ease: "easeInOut" },
                rotateY: { duration: 0.7, ease: "easeInOut" }
              }}
            >
              <Box
                component="img"
                src={cover}
                alt={`Book cover ${index + 1}`}
                sx={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "contain",
                  transition: "all 0.3s ease-in-out",
                }}
              />
            </motion.div>
          ))}
        </motion.div>
      </Box>
    </Box>
  );
};

export default MovingBookCovers;
