import React, { useRef, useCallback } from "react";
import { useInfiniteQuery } from 'react-query';
import Post from "./components/post.js";
import SearchBar from "./components/searchbar.js"; // Import the new SearchBar component
import { API_BASE_URL, API_ENDPOINTS } from '../../mainComponents/api.js';
import FullScreenLoading from "../../components/loadingScreen.js";
import './components/IndexPage.css';
import PostSkeleton from "./components/skeletonLoader.js"; // Import your skeleton loader component
import {  useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../mainComponents/theme.js';

// Function to fetch posts
const fetchPosts = async ({ pageParam = 1 }) => {
    const username = localStorage.getItem('username') || null;
    const response = await fetch(`${API_BASE_URL}${API_ENDPOINTS.FETCH}?page=${pageParam}&limit=6&username=${username}`);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
};

export default function IndexPage() {
    const scrollContainerRefs = useRef({});
    const navigate = useNavigate();
    const { isDarkMode } = React.useContext(ThemeContext);
    // Use react-query to fetch posts with infinite scrolling
    
    const {
        data,
        fetchNextPage,
        hasNextPage,
        isLoading,
        isFetchingNextPage,
    } = useInfiniteQuery('posts', fetchPosts, {
        getNextPageParam: (lastPage, pages) => {
            const hasMore = Object.values(lastPage).some(genre => 
                genre.total > (genre.page * genre.limit)
            );
            return hasMore ? pages.length + 1 : undefined;
        },
        staleTime: 1000 * 60 * 60, // Data considered fresh for 1 hour
        cacheTime: 1000 * 60 * 60 * 24, // Cache retained for 24 hours
    });

    const loadMoreForGenre = useCallback((genre) => {
        if (hasNextPage) {
            fetchNextPage();
        }
    }, [fetchNextPage, hasNextPage]);

    const handleScroll = useCallback((genre) => {
        const container = scrollContainerRefs.current[genre];
        if (container) {
            const { scrollLeft, scrollWidth, clientWidth } = container;
            if (scrollWidth - scrollLeft <= clientWidth + 10) { // Adding a buffer
                loadMoreForGenre(genre);
            }
        }
    }, [loadMoreForGenre]);

    const scroll = (categoryName, direction) => {
        const container = scrollContainerRefs.current[categoryName];
        if (container) {
            const scrollAmount = direction === 'left' ? -300 : 300;
            container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    if (isLoading) return <FullScreenLoading />;

    // Combine all pages of data
    const allGenres = (data?.pages || []).reduce((acc, page) => {
        Object.entries(page).forEach(([genre, genreData]) => {
            if (!acc[genre]) {
                acc[genre] = { ...genreData, books: [] };
            }
            acc[genre].books = [...acc[genre].books, ...(genreData.books || [])];
        });
        return acc;
    }, {});

    // Function to handle selection of a book title from suggestions
    const handleBookSelect = (title) => {
        console.log("Selected book:", title);
        navigate(`/book/${title}`);
    };
    if (localStorage.getItem('username')) {
        if (localStorage.getItem('username') === "admin") {
            navigate(`/create`);
        }
    } 
    return (
        <div className="categories-container">
            <SearchBar onSelect={handleBookSelect} /> {/* Integrate SearchBar */}
            
            {/* Render Recommendations first */}
            {allGenres["Recommendations"] && (
                <div className="category recommendations" style={{
                    backgroundColor: ' rgba(7, 31, 56, 0.11)', // Light blue background
                    border: '2px  rgba(6, 76, 109, 0.3)', // Blue border
                    borderRadius: '10px', // Rounded corners
                    padding: '20px', // Space inside the box
                    marginBottom: '40px', // Space below recommendations
                    boxShadow: '0 4px 15px rgba(11, 122, 156, 0.64)', // Subtle shadow effect
                    marginTop: '30px', // Space above recommendations
                }}>
                    <h2 className="genre-title" style={{
                        fontSize: '26px', // Larger font size for titles
                        color: isDarkMode? 'white': ' rgba(8, 50, 94, 0.82)', // Title color
                        textAlign: 'center', // Center align the title
                        fontWeight: 'bold', // Make the title bold
                    }}>Recommendations for you</h2>
                    <div className="posts-scroll-wrapper">
                        <button className="scroll-button left" onClick={() => scroll("Recommendations", 'left')}>
                            &#8249;
                        </button>
                        <div 
                            className="posts-scroll-container"
                            ref={(el) => (scrollContainerRefs.current["Recommendations"] = el)}
                            onScroll={() => handleScroll("Recommendations")}
                        >
                            {allGenres["Recommendations"].books.length > 0 ? (
                                allGenres["Recommendations"].books.map((book) => (
                                    <Post key={book.slug} post={book} />
                                ))
                            ) : (
                                <p>No books available in this category.</p>
                            )}
                            {isFetchingNextPage && (
                                <>
                                    {/* Show skeleton loaders while fetching more posts */}
                                    <PostSkeleton />
                                    <PostSkeleton />
                                    <PostSkeleton />
                                </>
                            )}
                        </div>
                        <button className="scroll-button right" onClick={() => scroll("Recommendations", 'right')}>
                            &#8250;
                        </button>
                    </div>
                </div>
            )}
    
            {/* Render other genres */}
            {Object.entries(allGenres).filter(([genre]) => genre !== "Recommendations").map(([genre, genreData]) => (
                <div key={genre} className="category">
                    <h2 className="genre-title">{genre}</h2>
                    <div className="posts-scroll-wrapper">
                        <button className="scroll-button left" onClick={() => scroll(genre, 'left')}>
                            &#8249;
                        </button>
                        <div 
                            className="posts-scroll-container"
                            ref={(el) => (scrollContainerRefs.current[genre] = el)}
                            onScroll={() => handleScroll(genre)}
                        >
                            {genreData.books.length > 0 ? (
                                genreData.books.map((book) => (
                                    <Post key={book.slug} post={book} />
                                ))
                            ) : (
                                <p>No books available in this category.</p>
                            )}
                            {isFetchingNextPage && (
                                <>
                                    {/* Show skeleton loaders while fetching more posts */}
                                    <PostSkeleton />
                                    <PostSkeleton />
                                    <PostSkeleton />
                                </>
                            )}
                        </div>
                        <button className="scroll-button right" onClick={() => scroll(genre, 'right')}>
                            &#8250;
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
}
